import styled, { keyframes } from 'styled-components';
import { sidebarWidth } from '../../../../../../globals';

const opacityAnim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    padding-left: ${sidebarWidth}%;
    background-image: linear-gradient(to right, #2b2425 0%, #352e2f 100%);
    opacity: 0;
    animation: ${opacityAnim} .8s forwards;
`;

export const XXXZZZ = styled.div`
  transition: all 2s;
`;
