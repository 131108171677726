import React, { useState, useEffect } from 'react';
import BackgroundImage from 'gatsby-background-image';

import {
  Wrapper, Line, Dot, RedLine, RedDot, DotContainer, ArrowDot, Arrow,
  AnimatedHoverCircle, OnSlideChangeAnimation, DateArrow, DateContainer,
  TitleContainer, GradientContainer, ToolTip, NumberContainer, PersonsContainer,
  PersonWrapper, ImagePersonWrapper, ImagePersonFlex, SinglePersonContainer,
  PersonText
} from './styles';

const Timeline = ({
  handleIndex,
  dots,
  yearsArray,
  assets,
  titleArray,
  toolTipDesc,
  numbersArray,
  handleContentChoice,
  contentIndex,
  texts,
}) => {
  const [red, setRed] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [oldIndex, setOldIndex] = useState(0);
  const [madeAnimationRight, setAnimationRight] = useState(false);
  const [madeAnimationLeft, setAnimationLeft] = useState(false);
  const [clickedArrow, setClickedArrow] = useState(false);

  const timeline = React.createRef();


  useEffect(() => {
    setRed(0);
    setActiveIndex(0);
  }, [contentIndex]);

  const setNewPosition = (index, vector) => {
    if (index >= 0 && index <= dots - 1) {
      if (vector === 'left') {
        setAnimationLeft(true);
      } else if (vector === 'right') {
        setAnimationRight(true);
      }
      if (!clickedArrow) {
        setClickedArrow(true);
      }
      const oneWidth = 100 / (dots - 1);
      const elementWidth = oneWidth * (index);
      setRed(elementWidth);
      setOldIndex(activeIndex);
      setActiveIndex(index);
      handleIndex(index);
    }
  };
  const onAnimationEndLeft = () => {
    setAnimationLeft(false);
  };

  const onAnimationEndRight = () => {
    setAnimationRight(false);
  };
  const dotArray = [];
  for (let i = 0; i < dots; i++) {
    dotArray.push(i);
  }
  const allDots = dotArray.map((dot, index) => {
    const oneWidth = 100 / (dots - 1);
    const leftPosition = oneWidth * (index);

    const style = {
      position: 'absolute',
      left: `${leftPosition}%`,
      cursor: `pointer`
    }
    let exception = null;
    // yearsArray.forEach((item, i) => {
    //   if (item.index === index) {
    //     exception = item;      }
    // });
    let title = null;
    titleArray.forEach((item, i) => {
      if (item.index === index) {
        title = item;
      }
    });
    const titleStyle = {
      left: `${leftPosition + (0.5 * oneWidth)}%`,
      cursor: `pointer`
    }
    return (
      < DotContainer key={index} style={style} onClick={() => { setNewPosition(index) }} >
        <NumberContainer>
          {numbersArray[index]}
        </NumberContainer>
        <Dot />
        {(activeIndex >= index)
          && <RedDot
            widthElement={activeIndex === index && oldIndex - activeIndex < 0}
            showAll={index <= activeIndex}
          />}
      </DotContainer>
    )
  })
  return (
    <Wrapper>
      <GradientContainer />
      <Line ref={timeline}>
        <ArrowDot isLeft onClick={() => setNewPosition(activeIndex - 1, 'right')} isShowing={true} style={{ display: activeIndex === 0 ? 'none' : 'flex' }}>
          {(madeAnimationRight)
            && <OnSlideChangeAnimation onAnimationEnd={onAnimationEndRight} />}
          <AnimatedHoverCircle />
          <Arrow isLeft />
        </ArrowDot>
        <ArrowDot isRight onClick={() => setNewPosition(activeIndex + 1, 'left')} isShowing={true} style={{ display: activeIndex >= dots - 1 ? 'none' : 'flex' }}>
          <ToolTip clickedArrow={clickedArrow}>
            {toolTipDesc}
          </ToolTip>
          {(madeAnimationLeft)
            && <OnSlideChangeAnimation onAnimationEnd={onAnimationEndLeft} />}
          <AnimatedHoverCircle />
          <Arrow isRight />
        </ArrowDot>
        <RedLine redWidth={red} />
        {allDots}
      </Line>
      <PersonsContainer>
        <SinglePersonContainer onClick={() => { handleContentChoice(1) }} contentIndex={contentIndex === 1}>
          <PersonWrapper>
            <ImagePersonWrapper>
              <BackgroundImage fluid={assets.pilsudski.childImageSharp.fluid}>
                <ImagePersonFlex />
              </BackgroundImage>
            </ImagePersonWrapper>
          </PersonWrapper>
          <PersonText>
            {texts.text1}
          </PersonText>
        </SinglePersonContainer>
        <SinglePersonContainer onClick={() => { handleContentChoice(2) }} contentIndex={contentIndex === 2}>
          <PersonWrapper>
            <ImagePersonWrapper>
              <BackgroundImage fluid={assets.weygand.childImageSharp.fluid}>
                <ImagePersonFlex />
              </BackgroundImage>
            </ImagePersonWrapper>
          </PersonWrapper>
          <PersonText>
            {texts.text2}
          </PersonText>
        </SinglePersonContainer>
      </PersonsContainer>
    </Wrapper>
  )
};

export default Timeline;
