import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Wrapper, PersonWrapper, Image, Hover } from './styles';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import arrowImg from 'src/assets/images/_shared/button-arrow.svg';
import LeftColContent from '../../leftColContent/leftColContent';

const InitialView = ({ data, assets, hideInitialScreen, handleContentChoice }) => {

    const handleChoice = (choice) => {
        handleContentChoice(choice);
        hideInitialScreen();
    }

    return (
        <ParallaxMordo flexi additionalStyling={{ overflow: 'visible' }}>
            <Mordo
                mainscene
                move={{ x: -20, y: -10 }}
                background={{ image: assets.background.childImageSharp.fluid }}
            />
            <Mordo
                scene={1}
                scenelayer={{ size: '100%' }}
                opacity={0.2}
                move={{ x: 30, y: 30 }}
                background={{ image: assets.paralax.childImageSharp.fluid }}
            />
            <Mordo
                flexi
                scene={2}
                move={{ x: -10, y: -5 }}
            >
                <LeftColContent body={data.left} style={{ width: '45%', marginTop: '-5vh' }} />
                <MDXProvider components={{
                    p: (props) => <p {...props} tabIndex="0" />,
                }}>
                    <Wrapper>
                        <PersonWrapper onClick={() => handleChoice(1)}>
                            <Hover src={assets.hover.childImageSharp.fluid.src} />
                            <Image src={assets.pilsudski.childImageSharp.fluid.src} />
                            <MDXRenderer arrow={arrowImg} handleHide={hideInitialScreen}>
                                {data.center}
                            </MDXRenderer>
                        </PersonWrapper>
                        <PersonWrapper onClick={() => handleChoice(2)}>
                            <Hover src={assets.hover.childImageSharp.fluid.src} />
                            <Image src={assets.weygand.childImageSharp.fluid.src} />
                            <MDXRenderer arrow={arrowImg} handleHide={hideInitialScreen}>
                                {data.right}
                            </MDXRenderer>
                        </PersonWrapper>
                    </Wrapper>
                </MDXProvider>
            </Mordo>

        </ParallaxMordo >

    )
};

export default React.memo(InitialView);
