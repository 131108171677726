import styled, { keyframes, css } from 'styled-components';


const scaleAnimation = keyframes`
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
`;

const opacityAnim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const textureAnim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.1;
  }
`;


export const Wrapper = styled.div`
    flex: 1;
    background: inherit;
    z-index: 2;
    position: relative;
    overflow: hidden;
    background: #272323;
    display: flex;
    align-items: center;
`;


export const MapContainer = styled.div`
    ${({ fixedSize }) => fixedSize
    && css`
    position: absolute;
    width: 1336px;
    height: 970px;
    /* right: -1vw;         */
    `}
    
    & p {
      ${({ scaleDown }) => `
        transform: scale(${scaleDown}) !important;
        margin-top: ${(scaleDown * 0.8) * 8}px;
    `}
    }

    transform-origin: right center;
    overflow: hidden;
`;

export const Background = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 0;
    ${({ animate }) => animate
    && css`
        transform: scale(1.2);
        animation: ${scaleAnimation} 1s ease forwards,
        ${opacityAnim} 2s forwards;
        `}
`;

export const Texture = styled(Background)`
    opacity: 0.1;
    ${({ animate }) => animate
    && css`
        animation: ${textureAnim} 1s ease forwards;
        `}
`;



export const CircleImageWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const CircleImg = styled.img`
    /* border-radius: 50%; */
    padding: 3px;
`;


export const MainAnimation = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${({ top, right }) => `
        top: ${top ? top : 0}%;
        right: ${right ? right : 0}%;
    `}
`;

export const ArrowAnimation = styled.div`
  position: absolute;
`;

// EmptyWrapper potrzebny dla opakowania w diva dla react transitions. inaczej nie działają

export const EmptyWrapper = styled.div`
`;

export const OpacityWrapper = styled.div`
  opacity: 0;
  animation: ${opacityAnim} 1s forwards .5s;
  animation-delay: .3s;
  ${({ initialAnimation }) => initialAnimation
    && css`
        animation-delay: .5s;
        `}
`;



export const AssetsBackground = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 0;
`;
