import styled, { keyframes } from 'styled-components';
import { fonts, colors } from '../../../../../../globals';

const opacityAnim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;




export const Wrapper = styled.div`
    height: 100vh;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 3vw;
    opacity: 0;
    animation: ${opacityAnim} 2s linear forwards .5s;
`
export const Hover = styled.img`
    position: absolute;
    left: -1vw;
    top: 1vh;
    transition: opacity .5s linear;
    opacity: 0;
`

export const PersonWrapper = styled.div`
    position: relative;
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    text-align: center;
    opacity: 0.6;
    transition: opacity .8s linear;
    h3 {
        ${{ ...fonts.headline_2 }}
        margin-bottom: 1rem;
    }

    p {
        ${{ ...fonts.headline_4 }}
        font-size: 1.125rem;
        font-weight: 500;
        opacity: 0;
        transition: opacity .3s linear;
    }

    &:hover {
        opacity: 1;
        cursor: pointer;
        p {
            opacity: 1;
        }
        ${Hover} {
        opacity: 1;
    }
    }
`
export const Image = styled.img`
    position: relative;
`

