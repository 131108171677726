import React, { useState, useEffect } from 'react';
import { Wrapper, MapContainer, Background, Texture } from './styles';
import { Preload } from 'react-preload';
import texture from 'src/assets/images/chapter_3/sub_5/_shared/texture.png';
import { motion, AnimatePresence } from "framer-motion"
import { getLangFromPath } from 'src/utils/index.js'

// PILSUDSKI
import SubMap1 from './slide-1-1';
import SubMap2 from './slide-1-2';
import SubMap3 from './slide-1-3';
import Video from './slide-1-4';
import SubMap5 from './slide-1-5';
import SubMap6 from './slide-1-6';
import SubMap7 from './slide-1-7';
import SubMap8 from './slide-1-8';
import SubMap9 from './slide-1-9';
// WEYGANER
import Video2 from './slide-2-1';
import SubMap10 from './slide-2-2';
import SubMap11 from './slide-2-3';
import SubMap12 from './slide-2-4';
import SubMap13 from './slide-2-5';

const Map = ({ index, contentChoice }) => {
    const lang = getLangFromPath();
    // const lang = 'EN';

    const BACKGROUNDS = {
        1: {
            0: require(`../../../../assets/images/chapter_4/sub_5/${lang}/4-5-1-1.svg`),
            1: require(`../../../../assets/images/chapter_4/sub_5/${lang}/4-5-1-2.svg`),
            2: require(`../../../../assets/images/chapter_4/sub_5/${lang}/4-5-1-3.svg`),
            4: require(`../../../../assets/images/chapter_4/sub_5/${lang}/4-5-1-5.svg`),
            5: require(`../../../../assets/images/chapter_4/sub_5/${lang}/4-5-1-6.svg`),
            6: require(`../../../../assets/images/chapter_4/sub_5/${lang}/4-5-1-7.svg`),
            7: require(`../../../../assets/images/chapter_4/sub_5/${lang}/4-5-1-8.svg`),
            8: require(`../../../../assets/images/chapter_4/sub_5/${lang}/4-5-1-9.svg`),
        },
        2: {
            0: require(`../../../../assets/images/chapter_3/sub_5/3-5-17.svg`),// fake bckg 
            1: require(`../../../../assets/images/chapter_4/sub_5/${lang}/4-5-2-1.svg`),
            2: require(`../../../../assets/images/chapter_4/sub_5/${lang}/4-5-2-1.svg`),
            3: require(`../../../../assets/images/chapter_4/sub_5/${lang}/4-5-2-1.svg`),
            4: require(`../../../../assets/images/chapter_4/sub_5/${lang}/4-5-2-2.svg`),
        }
    }


    const windowDimentions = window.innerWidth / 1920 * 1.1;
    const noBackground = contentChoice === 1 ? 3 : null; // slides with no video
    const [mapScale, setMapScale] = useState(windowDimentions);
    const [initialAnimation, setInitialAnimation] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);

    window.addEventListener('resize', () => {
        setMapScale(window.innerWidth / 1920 * 1.05);
    })

    const SLIDES = {
        1: [<SubMap1 />, <SubMap2 />,
        <SubMap3 />, <Video />, <SubMap5 />,
        <SubMap6 />, <SubMap7 />,
        <SubMap8 />, <SubMap9 />],
        2: [<Video2 />, <SubMap10 />,
        <SubMap11 />, <SubMap12 />, <SubMap13 />]
    }

    useEffect(() => {
        index > 0 && setInitialAnimation(false)
        setImageLoaded(false);
    }, [index])

    useEffect(() => {
        setInitialAnimation(true);
    }, [contentChoice])

    const handleOnLoad = () => {
        setImageLoaded(true);
    }


    const variants = {
        initial: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
            transition: { duration: 1 },
        },
        exit: {
            opacity: 0.7,
            transition: { duration: 1 },
        },
    }

    return (
        <Wrapper >
            <Preload
                images={Object.values(BACKGROUNDS[contentChoice])}
                autoResolveDelay={5000}
                resolveOnError={true}
                mountChildren={true}
            >
                <MapContainer
                    style={{
                        transform: `scale(${mapScale})`,
                        right: contentChoice === 1 ? '-2vw' : '-9vw'
                    }}
                    fixedSize={true}
                    scaleDown={index !== noBackground ? 1 / mapScale : 1}
                >
                    <AnimatePresence
                        initial={false}
                    // onExitComplete={() => setMapsScreenVisible(true)}
                    >
                        <motion.div
                            key={index}
                            variants={variants}
                            initial="initial"
                            animate="enter"
                            exit="exit"
                        >
                            {index !== noBackground && <Background
                                key={index}
                                src={BACKGROUNDS[contentChoice][index]}
                                alt="map"
                                index={index}
                                onLoad={handleOnLoad}
                                animate={initialAnimation}
                            />}
                            {SLIDES[contentChoice][index]}
                        </motion.div>
                    </AnimatePresence >
                </MapContainer>
                {/* {contentChoice === 1 && index !== 3 || contentChoice === 2 && index !== 0 ?
                    < Texture src={texture} animate={initialAnimation}
                    /> : null} */}
            </Preload>
        </Wrapper>
    )
};

export default React.memo(Map);
