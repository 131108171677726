import React, { createRef, useEffect } from 'react';
import { OpacityWrapper, Background, MainAnimation } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation from './animations/slide-1-5.json';

import zeligowski from 'src/assets/images/chapter_4/sub_5/faces/zeligowski.png';
import sikorski from 'src/assets/images/chapter_4/sub_5/faces/sikorski.png';
import haller from 'src/assets/images/chapter_4/sub_5/faces/haller.png';
import rozwadowski from 'src/assets/images/chapter_4/sub_5/faces/rozwadowski.png';
import CircleImage from './elements/circleImage';

const Map = () => {

    return (
        <OpacityWrapper>
            <Background src={require(`../../../../assets/images/chapter_4/sub_5/4-5-1-6_icons.svg`)} />
            <CircleImage src={sikorski} top={33} right={42} />
            <CircleImage src={zeligowski} top={55} right={27} />
            <CircleImage src={haller} top={63} right={46} />
            <CircleImage src={rozwadowski} top={63} right={40} />
        </OpacityWrapper>
    )
};

export default Map;
