import React, { useEffect, createRef } from 'react';
import { MainAnimation, OpacityWrapper } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation from './animations/slide-2-4.json';


import gayk from 'src/assets/images/chapter_4/sub_5/faces/gayk.png';
import CircleImage from './elements/circleImage';

const Map = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation,
        });
        return () => anim.destroy();
    }, []);

    return (
        <OpacityWrapper>
            <MainAnimation ref={animationContainer} />
            <CircleImage src={gayk} top={9.5} right={48} />
        </OpacityWrapper>
    )
};

export default Map;
