import React, { createRef, useEffect } from 'react';
import { OpacityWrapper, Background, MainAnimation } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation from './animations/slide-1-5.json';

import gayk from 'src/assets/images/chapter_4/sub_5/faces/gayk.png';
import sikorski from 'src/assets/images/chapter_4/sub_5/faces/sikorski.png';
import haller from 'src/assets/images/chapter_4/sub_5/faces/haller.png';
import rozwadowski from 'src/assets/images/chapter_4/sub_5/faces/rozwadowski.png';
import CircleImage from './elements/circleImage';

const Map = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: animation,
        });
        setTimeout(() => { anim.play(); }, 1000);

        return () => anim.destroy();
    }, []);
    return (
        <OpacityWrapper>
            <MainAnimation ref={animationContainer} right={0.5} style={{ transform: 'scale(0.73)' }} />
            <Background src={require(`../../../../assets/images/chapter_4/sub_5/4-5-1-5_icons.svg`)} />
            <CircleImage src={gayk} top={15} right={72.5} />
            <CircleImage src={sikorski} top={41} right={49} />
            <CircleImage src={haller} top={63} right={46} />
            <CircleImage src={rozwadowski} top={63} right={40} />
        </OpacityWrapper>
    )
};

export default Map;
