import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import actions from "src/store/actions"

import { Wrapper } from './styles';
import Description from '../description/description';
import Map from '../map/map';
import Timeline from '../timeline/timeline';
import InitialView from '../initialView/initialView';
import { motion, AnimatePresence } from "framer-motion"


const variants = {
    initial: {
        opacity: 0,
    },
    enter: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.3 },
    },
}


const MainWrapper = ({ data, assets, titles, initialScreen, descriptionsElements,theme }) => {
    const [initialScreenVisible, setInitialScreenVisible] = useState(true);
    const [mapsScreenVisible, setMapsScreenVisible] = useState(false);
    const [contentChoice, setContentChoice] = useState(null);
    const dataArray = contentChoice && data[contentChoice];
    const [contentIndex, setContentIndex] = useState(0);
    const [numbers, setNumbers] = useState([]);
    const [dotsNumber, setDotsNumber] = useState(0);
    const dispatch = useDispatch()


    const handleIndex = (ix) => {
        if (ix === contentIndex) return;
        setContentIndex(ix);
    }

    const hideInitialScreen = () => {
        setInitialScreenVisible(false)
    }


    const numbersArray1 = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'];
    const numbersArray2 = ['I', 'II', 'III', 'IV', 'V'];
    const numberOfPilsudzkiDots = 9;
    const otherGuyDots = 5;

    const handleContentChoice = (choice) => {
        setContentIndex(0);
        setContentChoice(choice);
        if (choice === 1) {
            setNumbers(numbersArray1);
            setDotsNumber(numberOfPilsudzkiDots);
        } else {
            setNumbers(numbersArray2);
            setDotsNumber(otherGuyDots);
        }
    }

    useEffect(() => {
        if (mapsScreenVisible) {
          dispatch({ type: actions.SET_SLIDER_THEME, payload: theme })
        }
        return () => {
          dispatch({ type: actions.SET_SLIDER_THEME, payload: null })
        }
      }, [mapsScreenVisible])

    return (
        <>

            <AnimatePresence
                initial={false}
                onExitComplete={() => setMapsScreenVisible(true)}
            >
                {initialScreenVisible && (
                    <motion.div
                        key={initialScreenVisible}
                        variants={variants}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                    >
                        <InitialView data={initialScreen}
                            assets={assets}
                            hideInitialScreen={hideInitialScreen}
                            handleContentChoice={handleContentChoice} />
                    </motion.div>
                )
                }
            </AnimatePresence >
            {mapsScreenVisible && dataArray && <Wrapper>
                <Description
                    dataArray={dataArray}
                    contentIndex={contentIndex}
                    assets={assets}
                />
                <Map index={contentIndex} contentChoice={contentChoice} />
                <Timeline
                    index={contentIndex}
                    titleArray={titles.exports.titleArray}
                    toolTipDesc={titles.frontmatter.toolTip}
                    handleIndex={handleIndex}
                    numbersArray={numbers}
                    assets={assets}
                    handleContentChoice={handleContentChoice}
                    dots={dotsNumber}
                    texts={descriptionsElements}
                    contentIndex={contentChoice}
                />
            </Wrapper>}
        </>
    )
};

export default MainWrapper;
