import React from 'react';
import { OpacityWrapper } from './styles';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';

import Video from 'src/assets/videos/chapter_4/sub-5/slide-2-1.webm';
import VideoMP4 from 'src/assets/videos/chapter_4/sub-5/slide-2-1.mp4';

const Map = () => {

    return (
        <OpacityWrapper>
            <BackgroundAnimation src={Video} mp4={VideoMP4} />
        </OpacityWrapper>
    )
};

export default Map;
