import React from 'react';
import { OpacityWrapper } from './styles';



const Map = () => {

    return (
        <OpacityWrapper>

        </OpacityWrapper>
    )
};

export default Map;
