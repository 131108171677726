import React from 'react';
import { CircleImg, CircleImageWrapper, CircleImageTitle } from '../styles'



const CircleImage = ({ src, top, right }) => {

    return (
        <CircleImageWrapper
            style={{
                top: `${top}%`,
                right: `${right}%`,
            }} >
            <CircleImg src={src} />
        </CircleImageWrapper>
    )
};

export default CircleImage;
