import React, { useEffect, useState } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils/index.js'
import { mediaServer } from '../../../../../globals';

import {
  Wrapper, Content, Header, Text,
} from './styles';

const MDXWrapper = ({ children, language, assets }) => (
  <MDXProvider components={{
    AudioPlayer,
  }}
  >
    <MDXRenderer
      weygandAudio={[`${mediaServer}/webm/${language}/45weygand.webm`, `${mediaServer}/mp4/${language}/45weygand.mp4`]}
      weygandImage={assets.audioWeygand.childImageSharp.fluid}
      glabiszAudio={[`${mediaServer}/webm/${language}/45glabisz.webm`, `${mediaServer}/mp4/${language}/45glabisz.mp4`]}
      glabiszImage={assets.audioGlabisz.childImageSharp.fluid}
      smolenskiAudio={[`${mediaServer}/webm/${language}/45smolenski.webm`, `${mediaServer}/mp4/${language}/45smolenski.mp4`]}
      smolenskiImage={assets.audioSmolenski.childImageSharp.fluid}
      sosnkowskiAudio={[`${mediaServer}/webm/${language}/45weygand.webm`, `${mediaServer}/mp4/${language}/45weygand.mp4`]}
      sosnkowskiImage={assets.audioSosnkowski.childImageSharp.fluid}
      lisiewiczAudio={[`${mediaServer}/webm/${language}/45lisiewicz.webm`, `${mediaServer}/mp4/${language}/45lisiewicz.mp4`]}
      lisiewiczImage={assets.audioLisiewicz.childImageSharp.fluid}
    >
      {children}
    </MDXRenderer>
  </MDXProvider>
)


const setStyles = (ix, header, contentIndex) => {
  if (header && ix < contentIndex) {
    return {
      left: 0,
      opacity: 1,
    };
  }

  if (ix === contentIndex) {
    return {
      transform: 'translate3d(0, 0, 0)',
      opacity: 1,
    };
  }

  if (ix < contentIndex) {
    return {
      position: 'absolute',
      transform: 'translate3d(-200%, 0, 0)',
      opacity: ix < contentIndex ? 0 : 1,
    };
  }

  if (ix > contentIndex) {
    return {
      position: 'absolute',
      transform: 'translate3d(200%, 0, 0)',
      opacity: ix > contentIndex ? 0 : 1,
    };
  }
};

const Description = ({ dataArray, contentIndex, assets }) => {
  const [initialAnimation, setInitialAnimation] = useState(true);
  const language = getLangFromPath().toLowerCase();

  useEffect(() => {
    contentIndex > 0 && setInitialAnimation(false)
  }, [contentIndex]);


  return (
    <Wrapper>
      <Content animate={initialAnimation} >
        {dataArray.map((el, ix) => {
          return (
            <Header key={ix}
              style={setStyles(ix, true, contentIndex)}
            >
              <MDXWrapper language={language} assets={assets}>
                {el.node.body}
              </MDXWrapper>
            </Header>
          )
        })}
        {dataArray.map((el, ix) => {
          return (
            <Text
              key={ix}
              style={setStyles(ix, false, contentIndex)}>
              <MDXWrapper language={language} assets={assets}>
                {el.node.body}
              </MDXWrapper>
            </Text>
          )
        })}
      </Content>
    </Wrapper>
  )
};

export default React.memo(Description);
