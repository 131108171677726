import styled, { css, keyframes } from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: ${({ translateX }) => `translateX(-${translateX}%)`};
  width: ${({ widthTimeline }) => widthTimeline || '300%'};
  display: flex;
  justify-content: space-around;
  z-index: 3;
  transition: transform 1s;
`;
export const GradientContainer = styled.div`
  height: 10rem;
  position: absolute;
  width: 134%;
  left: -15%;
  bottom: -5rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.28084737312893904) 50%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: grey;
`;
export const DotContainer = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  transform: rotate(45deg);
  overflow: hidden;
  top: -0.3rem;
`;
export const Dot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: white;
`;
const changeWidth = keyframes`
  0% {
    width: 0;
  }
  50% {
    width: 0;
  }
  100% {
    width: 0.6rem;
  }
`;

export const RedDot = styled.div`
  position: absolute;
  width: 0rem;
  ${({ showAll }) => showAll
    && `
    width: 0.6rem;
 `}
  height: 0.6rem;
  background-color: red;
  border-radius: 50%;
  animation: ${(props) => (props.widthElement
    ? css`
          ${changeWidth} cubic-bezier(0.820, 0.000, 0.195, 1.000) 0.5s forwards
        `
    : '')};
`;
export const RedLine = styled.div`
  width: ${(props) => props.redWidth}%;
  height: 1px;
  position: absolute;
  background-color: red;
  transition: 0.5s;
  top: 0;
  left: 0;
`;
export const DateArrow = styled.div`
  position: absolute;
  width: 1px;
  height: 2rem;
  bottom: -1rem;
  left: 0;
  background-color: white;
`;
export const DateContainer = styled.div`
  position: absolute;
  font-size: 1.5rem;
  font-family: Abril Fatface, Prata;
  transform: translate(-50%);
  bottom: -3rem;
  color: white;
`;
export const TitleContainer = styled.div`
  position: absolute;
  font-size: 0.75rem;
  font-family: Abril Fatface, Prata;
  border-left: 1px solid white;
  height: 3rem;
  width: 10rem;
  color: #fff;
  padding: 0 0.3rem;
  display: flex;
  ${({ positionTop }) => positionTop
    && `
    bottom: 0;
    align-items: flex-start;
 `}
  ${({ positionBottom }) => positionBottom
    && `
   top: 0;
   align-items: flex-end;
`}
`;

export const AnimatedHoverCircle = styled.div`
  width: 2.9375rem;
  height: 2.9375rem;
  background-color: transparent;
  border: 1px solid #ebe6e6;
  visibility: hidden;
  z-index: 100;
  position: absolute;
  border-radius: 100%;
`;

const slideChange = keyframes`
  0% {
    clip-path: polygon(44% 54%, 45% 0, 45% 0);
  }
  20% {
    clip-path: polygon(44% 54%, 110% 0, 45% 0);
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(360deg);
    clip-path: polygon(44% 54%, 45% 0, 0 0);
  }
  100% {
    clip-path: polygon(44% 54%, 45% 0, 45% 0);
    transform: rotate(360deg);
  }
`;
export const OnSlideChangeAnimation = styled.div`
  width: 3.1rem;
  height: 3.1rem;
  position: absolute;
  clip-path: polygon(44% 54%, 110% 0, 45% 0);
  border: 0.125rem solid #de2831;
  border-radius: 100%;
  animation: ${slideChange} 1s cubic-bezier(0.82, 0, 0.195, 1) forwards;
`;

export const ArrowDot = styled.div`
  width: 2.9375rem;
  height: 2.9375rem;
  background-color: transparent;
  border: 1px solid #ebe6e6;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ isLeft }) => isLeft
    && `
    left: -10%;
 `}
   ${({ isRight }) => isRight
     && `
     right: -10%;
  `}
  ${({ isShowing }) => !isShowing
    && `
    visibility: hidden;
  `}
  z-index: 101;
  &:hover ${AnimatedHoverCircle} {
    transition: 1s cubic-bezier(0.820, 0.000, 0.195, 1.000);
    visibility: visible;
    transform: scale(2);
    opacity: 0;
  }
`;

export const Arrow = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-right: 2px solid #ebe6e6;
  border-bottom: 2px solid #ebe6e6;
  ${({ isLeft }) => isLeft
    && `
    transform: rotate(135deg);
    margin-left: 0.375rem;
 `};
  ${({ isRight }) => isRight
    && `
     transform: rotate(-45deg);
     margin-left: -0.375rem;
  `};
`;

export const ToolTip = styled.div`
  width: 12rem;
  height: 5rem;
  background-color: #f6f5ef;
  color: #302a29;
  border-radius: 0.25rem;
  position: absolute;
  top: -8rem;
  font-family: Red Hat Display, Open Sans;
  font-size: 0.875rem;
  line-height: 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  text-align: center;
  transition: 0.5s;
  ${({ clickedArrow }) => clickedArrow
    && `
    opacity: 0;
 `};
  &:after {
    position: absolute;
    bottom: -1.6rem;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    width: 0;
    height: 0;
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    border-top: 2.2rem solid #f6f5ef;
  }
`;

export const NumberIndex = styled.span`
  color: #f9f8f3;
  font-family: "Abril Fatface";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.875rem;
  text-transform: uppercase;
  bottom: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.8rem;
`;
