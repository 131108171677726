import React, { createRef, useEffect } from 'react';
import { OpacityWrapper, Background, MainAnimation } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation from './animations/slide-1-3.json';
import jegorow from 'src/assets/images/chapter_4/sub_5/faces/jegorow.png';
import stalin from 'src/assets/images/chapter_4/sub_5/faces/stalin.png';
import budionny from 'src/assets/images/chapter_4/sub_5/faces/budionny.png';
import CircleImage from './elements/circleImage';

const Map = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: animation,
        });
        setTimeout(() => { anim.play(); }, 1000);

        return () => anim.destroy();
    }, []);
    return (
        <OpacityWrapper>
            <MainAnimation ref={animationContainer} right={0.5} top={0.5} />
            <Background src={require(`../../../../assets/images/chapter_4/sub_5/4-5-1-3_icons.svg`)} />
            <CircleImage src={jegorow} top={34} right={6.5} />
            <CircleImage src={stalin} top={41} right={2.5} />
            <CircleImage src={budionny} top={59} right={11} />
        </OpacityWrapper>
    )
};

export default Map;
