import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import MapSlider from '../../../../../components/desktop/mapSlider2/mainWrapper/mainWrapper';
import { SlideLarge } from '../../_styles';

const Slide = ({
  query, titles, initialScreen, timelineTexts,
}) => {
  const assetQuery = useStaticQuery(graphql`
    query {
  background: file(relativePath: { eq: "chapter_4/sub_5/main_bg.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  paralax: file(relativePath: { eq: "chapter_4/sub_5/paralax.png"}) {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  pilsudski: file(relativePath: { eq: "chapter_4/sub_5/pilsudski.png"}) {
    childImageSharp {
      fluid(maxWidth: 488) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  weygand: file(relativePath: { eq: "chapter_4/sub_5/weygand.png"}) {
    childImageSharp {
      fluid(maxWidth: 488) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  hover: file(relativePath: { eq: "chapter_4/sub_5/hover.png"}) {
    childImageSharp {
      fluid(maxWidth: 700) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  },
  audioWeygand: file(relativePath: { eq: "chapter_4/sub_5/audio-weygand.png"}) {
    childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
},
audioGlabisz: file(relativePath: { eq: "chapter_4/sub_5/audio-glabisz.png"}) {
  childImageSharp {
      fluid(maxWidth: 275) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
},
audioSmolenski: file(relativePath: { eq: "chapter_4/sub_5/audio-smolenski.png"}) {
  childImageSharp {
      fluid(maxWidth: 275) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
},
audioSosnkowski: file(relativePath: { eq: "chapter_4/sub_5/audio-sosnkowski.png"}) {
  childImageSharp {
      fluid(maxWidth: 275) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
},
audioLisiewicz: file(relativePath: { eq: "chapter_4/sub_5/audio-lisiewicz.png"}) {
  childImageSharp {
      fluid(maxWidth: 275) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
},

  }
  `);

  // TODO: brakuje tłumaczenia 4-5-1-8.mdx

  return (
    <SlideLarge>
      <MapSlider
        data={query}
        assets={assetQuery}
        titles={titles}
        initialScreen={initialScreen}
        descriptionsElements={timelineTexts}
      />
    </SlideLarge>
  );
};

export default Slide;
