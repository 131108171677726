import React, { createRef, useEffect } from 'react';
import { OpacityWrapper, Background, MainAnimation } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation from './animations/slide-1-8.json';

import sikorski from 'src/assets/images/chapter_4/sub_5/faces/sikorski_sm.png';
import zeligowski from 'src/assets/images/chapter_4/sub_5/faces/zeligowski_sm.png';
import haller from 'src/assets/images/chapter_4/sub_5/faces/haller_sm.png';
import rozwadowski from 'src/assets/images/chapter_4/sub_5/faces/rozwadowski_sm.png';
import pilsudski from 'src/assets/images/chapter_4/sub_5/faces/pilsudski_sm.png';
import noname from 'src/assets/images/chapter_4/sub_5/faces/noname_sm.png';

import CircleImage from './elements/circleImage';

const Map = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: animation,
        });
        setTimeout(() => { anim.play(); }, 1000);

        return () => anim.destroy();
    }, []);
    return (
        <OpacityWrapper>
            <MainAnimation ref={animationContainer} right={2} top={0} />
            <Background src={require(`../../../../assets/images/chapter_4/sub_5/4-5-1-8_icons.svg`)} />
            <CircleImage src={sikorski} top={24.5} right={63.5} />
            <CircleImage src={zeligowski} top={37} right={55} />
            <CircleImage src={haller} top={40} right={65.5} />
            <CircleImage src={rozwadowski} top={40} right={61} />
            <CircleImage src={pilsudski} top={61} right={39} />
            <CircleImage src={noname} top={62.5} right={33} />
        </OpacityWrapper>
    )
};

export default Map;
