import React from "react"
import TextAnimation from "src/components/mobile/textAnimation/textAnimation"
import BackgroundPhoto from "src/components/mobile/backgroundPhoto/backgroundPhoto"
import { useDispatch } from "react-redux"
import actions from "src/store/actions"
import ModalSliderPilsudski from "./ModalSliderPilsudski"
import ModalSliderWeygand from "./ModalSliderWeygand"
import {
  BlockContainer,
  ButtonWithBackground,
  AvatarFull,
  ChpaterTitle,
} from "../../_styles"

const Slide = ({ query, title, buttonText, buttonText2 }) => {
  const dispatch = useDispatch()
  const dispatchModalPilsudski = () => {
    dispatch({
      type: actions.SET_MODAL_CONTENTS,
      payload: <ModalSliderPilsudski query={query} />,
    })
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: null })
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true })
    dispatch({ type: actions.SET_MODAL_TYPE, payload: "mobile" })
  }
  const dispatchModalWeygand = () => {
    dispatch({
      type: actions.SET_MODAL_CONTENTS,
      payload: <ModalSliderWeygand query={query} />,
    })
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: null })
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true })
    dispatch({ type: actions.SET_MODAL_TYPE, payload: "mobile" })
  }
  return (
    <BlockContainer height="1800px">
      <BackgroundPhoto
        fixed={query.file.childImageSharp.fixed}
        scale
        alt="slide-photo"
      >
        <ChpaterTitle marginTop="12rem">{title}</ChpaterTitle>
        <TextAnimation
          body={query.mdx.body}
          containerStyle={{ height: "20%" }}
        />
        <AvatarFull
          fixed={query.pilsudski.childImageSharp.fixed}
          style={{ marginBottom: "5rem" }}
          title={query.titlePilsudski.frontmatter.mobileTitle}
        />
        <ButtonWithBackground
          onClick={() => dispatchModalPilsudski()}
          style={{ color: "#e7e5e5" }}
        >
          {buttonText}
        </ButtonWithBackground>
        <AvatarFull
          fixed={query.weygand.childImageSharp.fixed}
          style={{ marginBottom: "5rem", marginTop: "2rem" }}
          title={query.titleWeygand.frontmatter.mobileTitle}
        />
        <ButtonWithBackground
          onClick={() => dispatchModalWeygand()}
          style={{ color: "#e7e5e5" }}
        >
          {buttonText2 ? buttonText2 : buttonText}
        </ButtonWithBackground>
      </BackgroundPhoto>
    </BlockContainer>
  )
}

export default Slide
