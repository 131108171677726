import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/de/chapter_4/sub_5/slide1';

import WrapperMobile from 'src/slides/mobile/de/chapter_4/sub_5/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Plan und Verlauf | Schlacht bei Warschau" lang="de" description="Animierte Geschichte der Schlacht bei Warschau" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="Plan und Verlauf | Schlacht bei Warschau" lang="de" description="Animierte Geschichte der Schlacht bei Warschau" />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
