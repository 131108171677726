/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import {
  BlockContainer,
  Arrow,
  ArrowSliderLeft,
  ArrowSliderRight,
} from '../../_styles';
import SimpleTimeline from '../../../../../components/mobile/simpleTimeline/simpleTimeline';

const Slide = ({ text }) => {
  const assetQuery = useStaticQuery(graphql`
    query {
      file(
        relativePath: { eq: "chapter_4/sub_5/4-5-1-modal2__mobile.png" }
      ) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (

    <BlockContainer height="100vh">
      <BackgroundPhoto
        fixed={assetQuery.file.childImageSharp.fixed}
        scale
        alt="slide-photo"
      >
        <div style={{ height: '53%', marginTop: '10rem', overflowY: 'auto' }}>
          <TextAnimation body={text} containerStyle={{ height: 'auto' }} />
        </div>
      </BackgroundPhoto>
    </BlockContainer>
  );
};

const CHAPTERS = {
  0: [0, 1, 2, 3],
  4: [4, 5, 6],
  7: [7, 8, 9, 10],
  11: [11, 12, 13, 14],
  15: [15, 16],
  17: [17],
  18: [18],
  19: [19],
};

const ModalSlider = ({ query }) => {
  const [contentIndex, setContentIndex] = useState(0);
  const [slider, setSlider] = useState(null);
  const [currentChapter, setCurrentChapter] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    afterChange: (index) => setActiveIndex(index),
    onReInit: () => slider.slickGoTo(activeIndex),
  };

  const handleIndex = (ix) => {
    if (ix === contentIndex) return;
    setContentIndex(ix);
    for (const value in CHAPTERS) {
      if (CHAPTERS[value].some((el) => el === ix)) {
        value !== currentChapter && setCurrentChapter(+value);
      }
    }
  };
  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <Slider ref={(slider) => setSlider(slider)} {...settings}>
        {query.data2.edges.map((el, i) => <Slide key={i} text={el.node.body} />)}
      </Slider>
      <SimpleTimeline
        index={1}
        handleIndex={handleIndex}
        dots={5}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        translateX={34 + activeIndex * 7.96}
        widthTimeline="150%"
      />
      <ArrowSliderLeft onClick={() => setActiveIndex(activeIndex - 1)}>
        <Arrow reverse />
      </ArrowSliderLeft>
      <ArrowSliderRight onClick={() => setActiveIndex(activeIndex + 1)}>
        <Arrow />
      </ArrowSliderRight>
    </div>
  );
};

export default ModalSlider;
