import React, { useState, useEffect } from 'react';

import {
  Wrapper,
  Line,
  Dot,
  RedLine,
  RedDot,
  DotContainer,
  AnimatedHoverCircle,
  OnSlideChangeAnimation,
  GradientContainer,
  NumberIndex,
} from './styles';

const romanNumbers = [
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII',
  'XIII',
  'XIV',
  'XV',
  'XVI',
  'XVII',
  'XVIII',
  'XIX',
  'XX',
];

const SimpleTimeline = ({
  handleIndex,
  dots,
  activeIndex,
  setActiveIndex,
  translateX,
  widthTimeline,
}) => {
  const [red, setRed] = useState(100 / (dots + 1));
  const [oldIndex, setOldIndex] = useState(0);
  const [madeAnimationRight, setAnimationRight] = useState(false);
  const [madeAnimationLeft, setAnimationLeft] = useState(false);
  const [clickedArrow, setClickedArrow] = useState(false);

  const timeline = React.createRef();

  const setNewPosition = (index, vector) => {
    if (index >= 0 && index <= dots) {
      if (vector === 'left') {
        setAnimationLeft(true);
      } else if (vector === 'right') {
        setAnimationRight(true);
      }
      if (!clickedArrow) {
        setClickedArrow(true);
      }
      const oneWidth = 100 / (dots + 1);
      const elementWidth = oneWidth * (index + 1);
      setRed(elementWidth);
      setOldIndex(activeIndex);
      setActiveIndex(index);
      handleIndex(index);
    }
  };

  useEffect(() => {
    setNewPosition(activeIndex);
  }, [activeIndex]);

  const onAnimationEndLeft = () => {
    setAnimationLeft(false);
  };

  const onAnimationEndRight = () => {
    setAnimationRight(false);
  };
  const dotArray = [];
  for (let i = 0; i < dots; i++) {
    dotArray.push(i);
  }
  const allDots = dotArray.map((dot, index) => {
    const oneWidth = 100 / (dots + 1);
    const leftPosition = oneWidth * (index + 1);

    const style = {
      position: 'absolute',
      left: `${leftPosition}%`,
      cursor: 'pointer',
    };

    const styleNumber = {
      position: 'absolute',
      left: `${leftPosition}%`,
    };
    return (
      <div key={index}>
        <NumberIndex style={styleNumber}>{romanNumbers[index]}</NumberIndex>
        <DotContainer
          style={style}
          onClick={() => {
            setNewPosition(index);
          }}
        >
          <Dot />
          {activeIndex >= index && (
            <RedDot
              widthElement={activeIndex === index && oldIndex - activeIndex < 0}
              showAll={index <= activeIndex}
            />
          )}
        </DotContainer>
      </div>
    );
  });

  return (
    <Wrapper translateX={translateX} widthTimeline={widthTimeline}>
      <GradientContainer />
      <Line ref={timeline}>
        {madeAnimationRight && (
          <OnSlideChangeAnimation onAnimationEnd={onAnimationEndRight} />
        )}
        <AnimatedHoverCircle />
        {madeAnimationLeft && (
          <OnSlideChangeAnimation onAnimationEnd={onAnimationEndLeft} />
        )}
        <AnimatedHoverCircle />
        <RedLine redWidth={red} />
        {allDots}
      </Line>
    </Wrapper>
  );
};

export default SimpleTimeline;
