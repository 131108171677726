import React, { createRef, useEffect } from 'react';
import { OpacityWrapper, Background, MainAnimation } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation from './animations/slide-1-9.json';

const Map = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: animation,
        });
        setTimeout(() => { anim.play(); }, 1000);

        return () => anim.destroy();
    }, []);
    return (
        <OpacityWrapper>
            <MainAnimation ref={animationContainer} right={1} top={0.5} />

        </OpacityWrapper>
    )
};

export default Map;
